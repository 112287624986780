html {
  background-color: black;
  color: white;

}

img {max-width:100%;}

.HomePage {
  display: grid;
  font-family: 'Roboto';
  max-width: 800px;
  margin: 0px auto;
  padding: 40px;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 200px 1fr;
  grid-template-areas:  "logo-area logo-area"
                        "login-button login-button"
                        "main-area main-area"
                        "register-button register-button"
}

.circle {
  background: #4f4b1b;
  border: 3px solid #000;
  color: white;
  font: 12.5vw Arial, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 50vw;
  height: 50vw;
  text-align: center;
  display: flex;
}

.center-horizontally {
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.Logo {
  grid-area: logo-area;
  display: flex;
  align-content: center;
  justify-content: center;
}

.Header {
  text-align: center;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border: 1px white;
}

/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: black !important;
}

.Text {
 grid-area: text-area;
}

.Main {
  grid-area: main-area;
  justify-content: center;
  text-align: center;
  background-color: black;
}

.LoginButton {
  grid-area: login-button;
}

.RegisterButton {
  grid-area: register-button;
}
